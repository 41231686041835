import React, { useEffect } from 'react'
import Testimonial from '@/components/HomePage/Testimonial'
import { motion } from 'framer-motion'
import Image from 'next/image'
import BasicLayout from '@/components/Layouts/BasicLayout'
import Footer from '@/components/Footer'
import Link from 'next/link'
import mixpanel from 'mixpanel-browser'
import SignUp from '../components/SignUp'
import { getToken } from 'next-auth/jwt'
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

const childVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
}

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
}

export default function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isGradOpen,
    onOpen: onGradOpen,
    onClose: onGradClose,
  } = useDisclosure()

  useEffect(() => {
    mixpanel.init('b53e4087bdeef3afa147f7eb9e37140d')
    mixpanel.track('Page View', {
      PageName: 'Home',
    })
  }, [])

  const personTypeButtonStyles = `bg-blue text-white rounded-lg transition-colors 
  duration-200 text-md footerSM:text-lg xl:text-xl py-5 px-8 shadow-lg hover:shadow-lg 
  cursor-pointer hover:bg-blueHover font-semibold text-center w-[300px] footerSM:w-[350px] nav:w-auto`
  const modalButtonStyles = `bg-blue text-white rounded-lg transition-colors duration-200 text-md footerSM:text-lg xl:text-xl
  py-5 px-8 shadow-md hover:shadow-lg cursor-pointer hover:bg-blueHover font-bold`

  return (
    <BasicLayout
      title="StudentWise - Supercharging the Lafayette Community"
      overrideTitle={true}
    >
      <div className="flex flex-col">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="flex flex-col justify-center items-center bg-white  py-5 gap-8 footerSM:gap-10  md:gap-12 lg:gap-16 footerSM:py-8 
         md:py-12 lg:py-16"
        >
          <div className="w-[90vw] footerSM:w-[85vw] sm:w-[80vw] xxl:w-[1280px]">
            <h1 className="text-center md:text-left text-3xl footerSM:text-5xl xl:text-7xl font-helvetica font-semibold">
              Connecting <span className="text-blue">Communities</span> to
              Internships and Jobs
            </h1>
            <motion.h2
              className="text-center md:text-left mt-5 font-helvetica font-normal text-xl footerSM:text-2xl xl:text-4xl"
              variants={childVariants}
            >
              Our mission is to improve the recruiting process for companies and
              job seekers
            </motion.h2>
          </div>

          <motion.div
            className="bg-light w-full p-8 flex flex-col justify-center items-center"
            variants={childVariants}
          >
            <div
              className="w-[95vw] footerXM:w-[90vw] footerSM:w-[85vw] sm:w-[80vw] xxl:w-[1280px] 
            flex justify-around items-center bg-light "
            >
              <div
                className="hidden md:block w-[150px] h-[150px]
             ml:w-[200px] ml:h-[200px] lg:w-[300px] lg:h-[300px] xl:w-[400px] xl:h-[400px] relative "
              >
                <Image
                  src="homepage/desk-job.svg"
                  alt="Desk Job"
                  fill
                  className="color-red"
                />
              </div>
              <div className="flex flex-col ml-5 items-center ">
                <span className="text-lg footerXM:text-xl ml:text-2xl  mycommunity:text-3xl font-semibold">
                  StudentWise has helped students get:
                </span>
                <div className="flex mt-10">
                  <div className="flex flex-col justify-center items-center">
                    <span className="underline text-xl footerXM:text-2xl ml:text-3xl mycommunity:text-4xl cursor-default">
                      6 Internships
                    </span>
                    <span className="text-5xl footerXM:text-7xl mt-8">🙌</span>
                  </div>
                  {/* <div className="flex flex-col justify-center items-center">
                    <span className="underline ml-10  text-xl footerXM:text-2xl ml:text-3xl  mycommunity:text-4xl">
                      0 Full-time Jobs
                    </span>
                    <span className="text-4xl footerXM:text-6xl mt-8">🤝</span>
              </div>*/}
                </div>
              </div>
            </div>
          </motion.div>

          <motion.div
            className="w-full flex justify-center items-center my-8"
            variants={childVariants}
          >
            <div className="flex flex-col justify-center items-center gap-8 w-[95vw] footerXM:w-[90vw] footerSM:w-[85vw]  sm:w-[80vw] xxl:w-[1280px]">
              <Testimonial
                text="StudentWise inspired me to realize the importance of getting an internship. This prompted me to start applying to places, calling people, and interview prepping, which <b>led to me getting an assurance and accounting internship in the financial sector at EY-Parthenon.</b>"
                name="Aidan Bodner"
                title="UCSB Class of 2024"
                image="https://res.cloudinary.com/dzgx1nr00/image/upload/v1686868125/h67qqyefiftnufjzyoae.jpg"
              />
              <Testimonial
                text="StudentWise played a <b>crucial role in helping me land an amazing internship at Amazon</b>, providing me with a personal referral, invaluable resources, and guidance. Their dedication to assisting college students in finding internships and job opportunities is both exceptional and inspiring!"
                image="https://res.cloudinary.com/dzgx1nr00/image/upload/v1686868230/app88yfttikprik7upam.jpg"
                title="SBCC Class of 2024"
                name="Eizak Sanchez"
              />
              <Testimonial
                text="StudentWise helped me refine my resume and gave me advice about the job market, internships, and what I could do to increase my chances of getting a good job. All of this has impacted me greatly in my career and <b>helped me get a software engineering internship over the summer at Agilent Technologies.</b>"
                name="Elias Warres"
                title="UCSC Class of 2023"
                image="https://res.cloudinary.com/dzgx1nr00/image/upload/v1685400310/jdidcyijd5tuqn3f43iw.jpg"
              />
            </div>
          </motion.div>
        </motion.div>
      </div>
      <Footer />
    </BasicLayout>
  )
}
