import WebNav from './webNav'
import MobileNav from './mobileNav'
import Link from 'next/link'
import Image from 'next/image'
import { useSession } from 'next-auth/react'

const adminUserEmails = [
  'nikita@malinovsky.net',
  'nikita@studentwise.io',
  'shreekarpandey@gmail.com',
  'shreekar@studentwise.io',
]

export default function Navbar() {
  const { data: session, status } = useSession()
  let isAdmin = false
  if (
    session &&
    session.user &&
    session.user.email &&
    adminUserEmails.includes(session.user.email)
  ) {
    isAdmin = true
  }
  return (
    <div className="flex flex-col w-full justify-center font-helvetica font-normal items-center bg-white">
      <div className="flex flex-col w-[95vw] footerXM:w-[90vw] footerSM:w-[85vw] sm:w-[80vw] xxl:w-[1280px] justify-center pt-2 text-black">
        <nav className="flex mt-3 mb-5 text-lg justify-center items-center">
          <Link href={session ? '/' : '/'} className="mr-auto ml-5">
            <div className="flex items-center gap-3 text-3xl text-black">
              <Image src="/logo.png" alt="logo" width="32" height="46" />
              <span className="hidden sm:block font-bold font-lexend">
                StudentWise
              </span>
            </div>
          </Link>
          {/*<WebNav session={session} status={status} isAdmin={isAdmin}></WebNav>*/}
          {/*<MobileNav session={session} status={status}></MobileNav>*/}
        </nav>
      </div>
      <hr className="h-[1px] opacity-50 bg-[#C2C2C2] w-full border-0 rounded "></hr>
    </div>
  )
}
