import Image from 'next/image'
import { Avatar } from '@chakra-ui/react'

interface TestimonialProps {
  text: string
  name: string
  title: string
  image: string
}

export default function Testimonial({
  text,
  name,
  title,
  image,
}: TestimonialProps) {
  return (
    <div className="flex flex-col md:flex-row bg-white shadow-md rounded-xl p-3 items-center text-lg max-w-[700px]">
      <div className="flex flex-col mr-4 ">
        {<span dangerouslySetInnerHTML={{ __html: text }} />}
        <span className="mt-2 hidden md:block">
          - {name}, {' ' + title}
        </span>
      </div>

      <div className="flex justify-around items-center md:w-[500px]">
        <Avatar className="mt-5 md:mt-0" src={image} name={name} size="xl" />
        <div className="ml-5 mt-2 flex flex-col md:hidden ">
          <span>{name}</span>
          <span>{title}</span>
        </div>
      </div>
    </div>
  )
}
