import Link from 'next/link'

import { useSession } from 'next-auth/react'
import { signIn, signOut } from 'next-auth/react'
import Image from 'next/image'

export default function Footer() {
  const { data: session } = useSession()
  return (
    <div className="flex flex-col w-full justify-center font-helvetica items-center bg-light shadow-lg ">
      <div className="flex w-[95vw] footerXM:w-[90vw] footerSM:w-[85vw]  sm:w-[80vw] xxl:w-[1280px] flex-col justify-center ">
        <div className="flex flex-col-reverse lg:flex-row items-center py-8 lg:py-10 mt-auto w-full text-black text-lg font-helvetica justify-center flex-wrap">
          <Link href={session ? '/' : '/'} className="lg:ml-auto mt-8 lg:mt-0">
            <div className="flex items-center gap-3 text-3xl text-black">
              <Image src="/logo.png" alt="logo" width="32" height="46" />
              <span className="block font-bold font-lexend">StudentWise</span>
            </div>
          </Link>
          <ol className="flex flex-wrap justify-center items-center mx-auto">
            <li>
              <Link
                href="/"
                passHref
                className="mx-3 whitespace-nowrap transition duration-[325ms]  hover:text-blue"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                href="/contact-us"
                passHref
                className="mx-3 whitespace-nowrap transition duration-[325ms]  hover:text-blue"
              >
                Learn More
              </Link>
            </li>
            <li>
              <a
                href="https://acalanesalumni.com/privacy-policy"
                target="_blank"
                className="mx-3 whitespace-nowrap transition duration-[325ms]  hover:text-blue"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}
